import { useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Link } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { useMutation, useStore } from '../../../../../utils/mst-hooks'
import { CherryPickingApplicant } from '../../../../../models/cherry-picking-applicants'
import { getCurrentMarketConfig } from '../../../../../services/market-service'
import AcceptAndRejectSection from '../accept-and-reject-buttons-section/accept-and-reject-buttons-section'
import TopQuality from '../top-quality/top-quality'
import Rating from '../../../../../components/rating/rating'
import beaconImg from '../../../../../components/assets/beacon.svg'
import AnimatedHeart from '../animated-heart/animated-heart'
import './applicant-card.scss'
import { ApplicantInterview } from '../applicant-interview/applicant-interview'
import { Separator } from '../../../../../components/app-layout/separator/separator'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'
import { SkillMatch } from '../skill-match/skill-match'
import ApplicantDetails from '../../../applicant-details/applicant-details'
import { Popup } from '../../../../../components/popup/popup'

type ApplicantCardProps = {
  applicant: CherryPickingApplicant
  jobId: string
  shiftId?: number
  onPlayAnswersPress: () => void
  onAccepted: (applicantId: number) => void
}

const ApplicantCard = withErrorBoundary(
  observer(({ applicant, jobId, shiftId, onPlayAnswersPress, onAccepted }: ApplicantCardProps) => {
    const [isProfileShown, setIsProfileShown] = useState(false)
    const { toggleApplicantAsFavouriteApiCall, getInterviewByJobId, getSpRating, spHasAnswers } = useStore().data
    const [toggleApplicantAsFavouriteQuery, toggleApplicantAsFavourite] = useMutation(toggleApplicantAsFavouriteApiCall)
    const markApplicantAsFavourite = () => {
      if (!applicant.is_favorite) {
        toast.success(t('applicant_details.added_as_favourite', { spName: applicant.name }))
      }
      toggleApplicantAsFavourite(String(applicant.id))
    }
    const isPhotoPDFType = useMemo(() => applicant.image_url?.includes('.pdf'), [applicant.image_url])

    const { t } = useTranslation()

    const showNationality = useMemo(() => getCurrentMarketConfig().applicantReview.includeNationality, [])
    const interview = getInterviewByJobId(parseInt(jobId))

    return (
      <div
        className={
          applicant.is_accepted || applicant.skillMatch === 100
            ? 'ApplicantCard ApplicantCard--selected'
            : 'ApplicantCard'
        }
      >
        <div className="ApplicantCard__header">
          <div className="ApplicantCard__avatarAndNameContainer" onClick={() => setIsProfileShown(true)}>
            <div className="ApplicantCard__link">
              <img className="ApplicantCard__avatar" src={isPhotoPDFType ? undefined : applicant.image_url ?? ''} />
              {applicant.is_chief ? <img className="ApplicantCard__beacon" src={beaconImg} /> : null}
            </div>
            <div className="ApplicantCard__nameAndRatingContainer">
              <div className="ApplicantCard__nameContainer">
                <h3 className="ApplicantCard__name">{applicant.name}</h3>
                {applicant.skillMatch === 100 && (
                  <span className="ApplicantCard__tag">{t('job_cherry_picking.shortlisted')}</span>
                )}
              </div>
              <Rating rating={applicant.rating ?? 0} editable={false} />
            </div>
          </div>
          <div className="ApplicantCard__favourite">
            <button
              className="ApplicantCard__favouriteIcon"
              disabled={toggleApplicantAsFavouriteQuery.isLoading}
              onClick={markApplicantAsFavourite}
            >
              <AnimatedHeart id={String(applicant.id)} checked={Boolean(applicant.is_favorite)} />
            </button>

            {applicant.top_qualities && <TopQuality name={applicant.top_qualities[0]} />}
          </div>
        </div>
        <Separator.Vertical height={20} />
        <Link
          route={routes.applicantDetails}
          params={{ applicantId: String(applicant.id), applicantName: applicant.name, jobId }}
          className="ApplicantCard__info"
        >
          <div className="ApplicantCard__infoColumn">
            <span className="ApplicantCard__infoName">{t('job_cherry_picking.exp')}</span>
            <span className="ApplicantCard__infoValue">
              {t('job_cherry_picking.years_of_experience', { count: applicant.years_experience })}
            </span>
          </div>
          <div className="ApplicantCard__infoColumn">
            <span className="ApplicantCard__infoName">{t('job_cherry_picking.age')}</span>
            <span className="ApplicantCard__infoValue">{applicant.age}</span>
          </div>
          <div className="ApplicantCard__infoColumn">
            <span className="ApplicantCard__infoName">{t('job_cherry_picking.language')}</span>
            <span className="ApplicantCard__infoValue">
              {applicant.languages[0]}
              {applicant.languages.length > 1 && (
                <span className="ApplicantCard__languageCount"> + {applicant.languages.length - 1}</span>
              )}
            </span>
          </div>
          {showNationality && (
            <div className="ApplicantCard__infoColumn">
              <span className="ApplicantCard__infoName">{t('job_cherry_picking.nationality')}</span>
              <span className="ApplicantCard__infoValue">{applicant.nationality}</span>
            </div>
          )}
        </Link>
        {interview !== undefined && spHasAnswers({ spId: applicant.id, interviewId: interview.id }) && (
          <ApplicantInterview
            rating={getSpRating({ interviewId: interview.id, spId: applicant.id })}
            className="ApplicantCard__applicantInterview"
            onClick={onPlayAnswersPress}
          />
        )}
        <SkillMatch
          skillMatch={applicant.skillMatch}
          skillIcons={applicant.skills
            .filter(skill => skill.is_matched)
            .map(skill => skill.icon ?? '')
            .slice(0, 3)}
          className="ApplicantCard__skillMatch"
        />
        <AcceptAndRejectSection jobId={jobId} applicant={applicant} shiftId={shiftId} onAccepted={onAccepted} />
        <Popup
          isActive={isProfileShown}
          content={
            <ApplicantDetails
              jobId={String(jobId)}
              applicantId={String(applicant.id)}
              applicantName={applicant.name}
              showHeader={false}
              showControls={false}
            />
          }
          close={() => setIsProfileShown(false)}
        />
      </div>
    )
  }),
)

export default ApplicantCard
