import { useTranslation } from 'react-i18next'

import Rating from '../rating/rating'
import { Checkbox } from '../checkbox/checkbox'
import { PastSPSnapshot } from '../../models/past-workers'
import './invited-sp.scss'
import heartIcon from './assets/heart.svg'
import { Separator } from '../app-layout'

export const InvitedSP = ({
  disabled,
  withCheckbox,
  onClick,
  sp,
  selected,
  showDesignation,
  jobDesignation,
  jobLocation,
}: {
  disabled: boolean
  withCheckbox: boolean
  onClick?: () => void
  sp: PastSPSnapshot
  selected: boolean
  showDesignation: boolean
  jobDesignation?: string | null
  jobLocation?: string | null
}) => {
  const { t } = useTranslation()

  const designation = jobDesignation
    ? sp.designations.find(name => name === jobDesignation) ?? sp.designations[0]
    : sp.designations[0]

  const workedAtJobLocation = jobLocation ? sp.locations.includes(jobLocation) : false

  return (
    <div onClick={onClick} key={sp.id} className={`InvitedSP${!disabled ? '' : ' InvitedSP--disabled'}`}>
      <div className="InvitedSP__body">
        <img className="InvitedSP__body__image" src={String(sp.image_url)} />
        <div className="InvitedSP__content">
          <div className="InvitedSP__titleContainer">
            <span className="InvitedSP__title">{sp.name}</span>
            {sp.is_favorite && <img src={heartIcon} />}
          </div>
          {showDesignation ? (
            <span className="InvitedSP__subtitle">
              {designation}
              {workedAtJobLocation && <strong> - {t('invited_sps.worked_at_current_location')}</strong>}
            </span>
          ) : (
            <Separator.Vertical height={10} />
          )}
          <Rating rating={Number(sp.client_rating_for_last_job)} />
        </div>
      </div>
      {withCheckbox && <Checkbox isActive={selected} type="square" disabled={disabled} />}
      {sp.is_available === false && <span className="InvitedSP__status">{t('common.not_available')}</span>}
    </div>
  )
}
