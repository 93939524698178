import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import { withErrorBoundary } from '../error/with-error-boundary'
import { StickyCallToAction } from '../sticky-call-to-action/sticky-call-to-action'
import { OrderState } from '../../models/order/list-item-orders'

const errorTypesToCatch = [
  ApiErrorTypes.ServerError,
  ApiErrorTypes.ConnectionError,
  ApiErrorTypes.ClientError,
  ApiErrorTypes.TooManyRequestsError,
  ApiErrorTypes.PermissionError,
]

export const PendingOrdersPanel = withErrorBoundary(
  observer(({ ordersNeedConfirmCount }: { ordersNeedConfirmCount: number }) => {
    const redirect = useRedirect()
    const { t } = useTranslation()

    const action = () => {
      redirect(routes.orders, { ordersState: OrderState.Unconfirmed })
      return
    }

    return (
      <section style={{ position: 'absolute', zIndex: 99 }}>
        <StickyCallToAction
          actionTitle={t('pending_orders_panel.pending_confirmation')}
          actionSubtitle={t('pending_orders_panel.n_orders', { count: ordersNeedConfirmCount })}
          action={action}
          buttonTitle={t('pending_orders_panel.confirm_now')}
        />
      </section>
    )
  }),
  null,
  null,
  errorTypesToCatch,
  true,
)
