import { useMemo, useEffect, useState, Fragment } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'
import { Image } from 'antd'
import moment from 'moment'

import { useRedirect } from '../../../services/router'
import { useStore, useQuery, useMutation, useDelayedQuery } from '../../../utils/mst-hooks'
import { CherryPickingApplicant } from '../../../models/cherry-picking-applicants'
import { Button } from '../../../components/button/button'
import { Separator } from '../../../components/app-layout/separator/separator'
import { IconButton } from '../../../components/icon-button/icon-button'
import { routes } from '../../../routes'
import Rating from '../../../components/rating/rating'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import beaconImg from '../../../components/assets/beacon.svg'
import Spacer from '../../order/components/spacer/spacer'
import arrowLeftImg from './assets/arrow-left.svg'
import arrowRightImg from './assets/arrow-right.svg'
import acceptedImg from './assets/accepted.svg'
import ApplicantInfo from '../job-cherry-picking/components/applicant-info/applicant-info'
import SeniorityLevel from '../job-cherry-picking/components/seniority-level/seniority-level'
import TopQuality from '../job-cherry-picking/components/top-quality/top-quality'
import { WorkExperienceCard } from './components/work-exp-card/work-exp-card'
import { EducationCard } from './components/education-card/education-card'
import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { SkillsList } from '../components/skills-list/skills-list'
import { InterviewSection } from '../components/interview-section/interview-section'
import { WorkCategorySkills } from './components/work-category-skills/work-category-skills'
import { ChiefCard } from './components/chief-card/chief-card'
import { ToggleFavourite } from '../components/toggle-favourite/toggle-favourite'
import './applicant-details.scss'
import { ClientExpCard } from './components/client-exp-card/client-exp-card'

type Props = {
  jobId: string
  applicantId: string
  applicantName: string
  shiftId?: number
  showHeader: boolean
  showControls: boolean
}

const ApplicantDetails = withErrorBoundary(
  observer(({ applicantId, jobId, applicantName, shiftId, showHeader, showControls }: Props) => {
    const {
      acceptApplicantApiCall,
      rejectApplicantApiCall,
      notAcceptedApplicantsList,
      getSPExperience,
      getExperienceBySpId,
      getApplicant,
      getInterview,
      currentApplicant,
      skillsGroupedByCategory,
    } = useStore().data
    const parsedJobId = parseInt(jobId)

    const {
      enableQuery: loadApplicant,
      query: { isLoading: applicantLoading },
    } = useDelayedQuery(() => getApplicant(parsedJobId, Number(applicantId)), {
      onSuccess: result => {
        setAccepted(Boolean(result.is_accepted))
      },
    })
    const { isLoading } = useQuery(() => getInterview({ jobId: parsedJobId }), {
      filterError: [ApiErrorTypes.NotFound],
    })

    useEffect(() => {
      loadApplicant()
    }, [jobId, applicantId])

    const experienceList = getExperienceBySpId(applicantId)
    const applicant = currentApplicant
    const [rating, setRating] = useState(applicant?.rating ?? 0)
    const [isRejected, setRejected] = useState(false)
    const [isAccepted, setAccepted] = useState(false)

    const [, getExperience] = useMutation(getSPExperience)

    const [, rejectApplicant] = useMutation(rejectApplicantApiCall, {
      onSuccess: () => setRejected(true),
    })
    const [, acceptApplicant] = useMutation(acceptApplicantApiCall, {
      onSuccess: () => {
        setAccepted(true)
        toast.success('Ogrammer is accepted!')
      },
    })

    useEffect(() => {
      getExperience({ jobId, spId: applicantId })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId, applicantId])

    const nextApplicant: CherryPickingApplicant = useMemo(() => {
      const indexOfCurrentApplicant = notAcceptedApplicantsList.findIndex(item => item.id === Number(applicantId))
      return indexOfCurrentApplicant === notAcceptedApplicantsList.length - 1
        ? notAcceptedApplicantsList[0]
        : notAcceptedApplicantsList[indexOfCurrentApplicant + 1]
    }, [notAcceptedApplicantsList, applicantId])

    const previousApplicant: CherryPickingApplicant = useMemo(() => {
      const indexOfCurrentApplicant = notAcceptedApplicantsList.findIndex(item => item.id === Number(applicantId))
      return indexOfCurrentApplicant === 0
        ? notAcceptedApplicantsList[notAcceptedApplicantsList.length - 1]
        : notAcceptedApplicantsList[indexOfCurrentApplicant - 1]
    }, [notAcceptedApplicantsList, applicantId])

    const isPhotoPDFType = useMemo(() => applicant?.image_url?.includes('.pdf'), [applicant])

    const redirect = useRedirect()

    const changePage = (type: 'next' | 'previous') => {
      redirect(routes.applicantDetails, {
        jobId,
        applicantId: type === 'next' ? String(nextApplicant.id) : String(previousApplicant.id),
        applicantName: nextApplicant.name,
      })
    }

    const { t } = useTranslation()

    const skills = currentApplicant?.skills ?? []

    const onAccept = () => {
      acceptApplicant({
        sp_id: String(applicantId),
        job_id: Number(jobId),
        shift_ids: shiftId ? [shiftId] : undefined,
      })
    }

    if (applicantLoading) {
      return (
        <div className="ApplicantDetails">
          <LoadingSpinner />
        </div>
      )
    }

    if (!applicant) return null

    return (
      <div className="ApplicantDetails">
        {showHeader && <Header title={applicantName} showBack />}
        <Spacer space={25} />
        <div className="ApplicantDetails__header">
          <ToggleFavourite className="ApplicantDetails__toggleFavourite" />
          <div className="ApplicantDetails__avatarContainer">
            {isPhotoPDFType ? (
              <img className="ApplicantDetails__avatarContainer__avatar" />
            ) : (
              <Image src={String(applicant.image_url)} className="ApplicantDetails__avatarContainer__avatar" />
            )}
            {applicant.is_chief ? <img src={beaconImg} className="ApplicantDetails__avatarContainer__beacon" /> : null}
          </div>
          <div className="ApplicantDetails__nameAndRatingContainer">
            <h2 className="ApplicantDetails__name">{applicant.name}</h2>
            {applicant.completed_shifts_count > 0 && (
              <p className="ApplicantDetails__shiftsCount">
                {t('applicant_details.n_shifts_completed', { count: applicant.completed_shifts_count })}
              </p>
            )}
            <Rating size={20} editable={false} onRatingChange={setRating} rating={rating} />
            {applicant.is_chief && (
              <div className="ApplicantDetails__nameAndRatingContainer__chiefContainer">
                <img src={beaconImg} className="ApplicantDetails__nameAndRatingContainer__chiefContainer__beacon" />
                <span className="ApplicantDetails__nameAndRatingContainer__chiefContainer__label">
                  {t('applicant_details.chief_og')}
                </span>
              </div>
            )}
          </div>
          <div className="ApplicantDetails__topQualitiesContainer">
            {applicant.top_qualities?.map((topQuality, i) => (
              <TopQuality containerStyleClass={'ApplicantDetails__topQuality'} key={i} name={topQuality} />
            ))}
          </div>
          <SkillsList skills={skills} />
          {skills.length > 0 && <Spacer space={21} />}
          <SeniorityLevel
            containerStyleClass={'ApplicantDetails__seniorityLevel'}
            seniority={applicant.seniority_level}
          />
        </div>
        {applicant.is_chief && (
          <>
            <Spacer space={16} />
            <ChiefCard />
          </>
        )}
        <div className="ApplicantDetails__separator" />
        <InterviewSection jobId={parsedJobId} spId={applicant.id} isLoading={isLoading} onAcceptPress={onAccept} />
        <div className="ApplicantDetails__separator" />
        <div className="ApplicantDetails__informationContainer">
          <h3 className="ApplicantDetails__infoTitle">{t('applicant_details.information')}</h3>
          <ApplicantInfo applicant={applicant} />
        </div>
        <Spacer space={25} />
        {skills.length > 0 && (
          <>
            <div className="ApplicantDetails__infoContainer">
              <span className="ApplicantDetails__infoContainer__title">{t('applicant_details.skills')}</span>
              <Spacer space={24} />
              {skillsGroupedByCategory.map((item, key) => (
                <WorkCategorySkills key={key} categoryTitle={item.workCategoryName} skills={item.skills} />
              ))}
            </div>
            <Spacer space={25} />
          </>
        )}
        {Boolean(applicant.past_jobs.length) && (
          <div className="ApplicantDetails__infoContainer">
            <span className="ApplicantDetails__infoContainer__title">
              {t('applicant_details.job_history_with_you')}
            </span>
            <Spacer space={24} />
            {applicant.past_jobs.map((pastJob, index) => (
              <>
                <ClientExpCard
                  title={pastJob.designation_name}
                  location={pastJob.location_name}
                  jobId={pastJob.id}
                  startDate={moment(pastJob.start_date).format('D MMM')}
                  endDate={pastJob.end_date ? moment(pastJob.end_date).format('D MMM') : null}
                />
                {index !== applicant.past_jobs.length - 1 && (
                  <div className="ApplicantDetails__infoContainer__separator" />
                )}
              </>
            ))}
          </div>
        )}
        <Spacer space={25} />
        {Boolean(experienceList?.experiences.length) && (
          <>
            <div className="ApplicantDetails__infoContainer">
              <span className="ApplicantDetails__infoContainer__title">{t('applicant_details.work_experience')}</span>
              <Spacer space={24} />
              {experienceList?.experiences.map((exp, index) => (
                <Fragment key={index}>
                  <WorkExperienceCard
                    title={exp.work_category_name}
                    companyName={exp.company_name}
                    location={exp.location_name}
                    startYear={exp.start_year}
                    endYear={exp.end_year}
                    description={exp.description}
                    imageURL={exp.image_url}
                  />
                  {index !== experienceList.experiences.length - 1 && (
                    <div className="ApplicantDetails__infoContainer__separator" />
                  )}
                </Fragment>
              ))}
            </div>
            <Spacer space={25} />
          </>
        )}
        {Boolean(experienceList?.educations.length) && (
          <div className="ApplicantDetails__infoContainer">
            <span className="ApplicantDetails__infoContainer__title">{t('applicant_details.education')}</span>
            <Spacer space={24} />
            {experienceList?.educations.map((item, index) => (
              <Fragment key={index}>
                <EducationCard school={item.school} degree={item.degree} field={item.study} />
                {index !== experienceList.educations.length - 1 && (
                  <div className="ApplicantDetails__infoContainer__separator" />
                )}
              </Fragment>
            ))}
          </div>
        )}
        {showControls && (
          <>
            <Spacer space={25} />
            <div className="ApplicantDetails__controlContainer">
              <div className="ApplicantDetails__controlContainer__content">
                {isAccepted ? (
                  <IconButton
                    title={t('job_cherry_picking.accepted')}
                    icon={acceptedImg}
                    className="ApplicantDetails__controlContainer__accepted"
                  />
                ) : isRejected ? (
                  <Button
                    title={t('job_cherry_picking.rejected')}
                    className="ApplicantDetails__controlContainer__rejected"
                  />
                ) : (
                  <>
                    <div
                      onClick={() => {
                        changePage('previous')
                      }}
                      className="ApplicantDetails__controlContainer__imgContainer"
                    >
                      <img src={arrowLeftImg} alt="get previous applicant" />
                    </div>
                    <div className="ApplicantDetails__controlContainer__buttonsContainer">
                      {!shiftId && (
                        <>
                          <Button
                            title={t('job_cherry_picking.reject')}
                            className="ApplicantDetails__controlContainer__buttonsContainer__rejectButton"
                            onClick={() => rejectApplicant({ sp_id: String(applicantId), job_id: Number(jobId) })}
                          />
                          <Separator.Horizontal width={12} />
                        </>
                      )}
                      <Button
                        title={t('job_cherry_picking.accept')}
                        className="ApplicantDetails__controlContainer__buttonsContainer__acceptButton"
                        onClick={onAccept}
                      />
                    </div>
                    <div
                      onClick={() => {
                        changePage('next')
                      }}
                      className="ApplicantDetails__controlContainer__imgContainer"
                    >
                      <img src={arrowRightImg} alt={t('applicant_details.get_next_applicant')} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        <Separator.Vertical height={70} />
      </div>
    )
  }),
)

export default ApplicantDetails
