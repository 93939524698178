import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Route, useRedirect } from '../../services/router'
import { useDelayedQuery, useStore } from '../../utils/mst-hooks'
import { routes } from '../../routes'
import { UserRole } from '../../models/account'
import { Navigation } from '../../pages'
import { DraftJobsStoreProvider, draftJobsStore } from '../../models-ui/order/store'
import { withOnboardingPage } from '../with-onboarding/with-onboarding-page'
import { ProtectedRoute } from '../protected-route/protected-route'
import { Header, Sidebar } from './index'
import './app-layout.scss'
import { OrderState } from '../../models/order/list-item-orders'
import { PaymentStatus } from '../../models/payment'
import { useLanguageUpdate } from '../../utils/use-language-update'
import { QrScanner } from '../qr-scanner/qr-scanner'
import { trackingService } from '../../services/tracking-service'
import { formatDateToIso8601 } from '../../utils/datetime-utils'

export const AppLayout = withOnboardingPage(
  observer(() => {
    const [desktopSidebarActive, setDesktopSidebarActive] = useState(true)

    const { logged, getUnseenUpdates, getConfig, getProfile } = useStore().data

    const { enableQuery: getUnseenUpdatesQuery } = useDelayedQuery(getUnseenUpdates)
    const { enableQuery: getConfigQuery } = useDelayedQuery(getConfig)
    const { enableQuery: getProfileQuery } = useDelayedQuery(getProfile, {
      onSuccess: profile => {
        trackingService.identifyUser(profile.user_id)
        trackingService.assignUserProperties(profile.id, profile.roles, profile.email)
      },
    })

    useEffect(() => {
      if (logged) {
        getUnseenUpdatesQuery()
        getConfigQuery()
        getProfileQuery()
      }
    }, [logged])

    const redirect = useRedirect()

    useLanguageUpdate()

    const { t } = useTranslation()

    return (
      <div id="main_layout" className="AppLayout">
        <div id="modals"></div>
        <>
          <div className="AppLayout__desktop">
            {logged && <Sidebar active={desktopSidebarActive} setActive={setDesktopSidebarActive} />}
          </div>
          <main className="AppLayout__main">
            <ToastContainer style={{ zIndex: '999999' }} />
            <QrScanner />
            <Route route={routes.index}>
              {logged ? (
                <DraftJobsStoreProvider value={draftJobsStore}>
                  <Navigation.HomeStack.HomePage hideLogo={desktopSidebarActive} />
                </DraftJobsStoreProvider>
              ) : (
                <Navigation.AuthStack.LoginPage />
              )}
            </Route>
            <Route route={routes.allJobs}>
              {params => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                  <Navigation.JobsStack.AllJobsPage date={params.date ?? formatDateToIso8601(new Date())} />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.pastWorkers}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                <Navigation.PastWorkers />
              </ProtectedRoute>
            </Route>
            <Route route={routes.editProfile}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                <Header title={t('profile_form.title')} />
                <Navigation.ProfileStack.ProfileFormPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.editCompany}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                <Navigation.ProfileStack.CompanyFormPage />
              </ProtectedRoute>
            </Route>

            <Route route={routes.locations}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                <Navigation.LocationsStack.LocationListPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.addLocations}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                <Navigation.LocationsStack.AddLocationPage
                  showSubmit
                  showLocationNameField
                  showAddressDetailsField
                  showImageField
                  showHeader
                  showCheckInRestrictionsBlock
                />
              </ProtectedRoute>
            </Route>

            <Route route={routes.editLocations}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                  <Navigation.LocationsStack.EditLocationPage id={p.id} />
                </ProtectedRoute>
              )}
            </Route>

            <Route route={routes.attendanceManagers}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                <Navigation.ProfileStack.AttendanceManagerListPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.addAttendanceManagers}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                <Navigation.ProfileStack.AddAttendanceManagerFormPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.editAttendanceManagers}>
              {params => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                  <Navigation.ProfileStack.EditAttendanceManagerPage id={params.id} />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.paymentMethods}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                <Navigation.ProfileStack.PaymentMethodListPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.invoices}>
              <>
                <Navigation.ProfileStack.InvoiceListPage />
              </>
            </Route>
            <Route route={routes.invoice}>
              {params => <Navigation.ProfileStack.InvoiceDetailsPage id={params.id} />}
            </Route>
            <Route route={routes.invoiceOgrammer}>
              {params => {
                return (
                  <>
                    <Header showBack title={params.name} />
                    <Navigation.ProfileStack.InvoiceOgrammerPage
                      invoiceItemId={params.invoiceItemId}
                      ogrammerId={params.ogrammerId}
                      ogrammerName={params.name}
                    />
                  </>
                )
              }}
            </Route>
            <Route route={routes.preferences}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                <Navigation.ProfileStack.PreferencesPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.applicantsReview}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                <Navigation.ApplicantsReviewStack.ApplicantsReviewPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.jobOGCherryPicker}>
              {(params: { jobId: string; shiftId?: string }) => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                  <Navigation.ApplicantsReviewStack.JobCherryPickingPage
                    jobId={params.jobId}
                    shiftId={params.shiftId}
                  />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.applicantDetails}>
              {(params: { jobId: string; applicantId: string; applicantName: string }) => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                  <Navigation.ApplicantsReviewStack.ApplicantDetailsPage
                    jobId={params.jobId}
                    applicantId={params.applicantId}
                    applicantName={params.applicantName}
                    showHeader
                    showControls
                  />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.attendanceShiftsList}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                <Navigation.ShiftsAttendanceStack.ShiftListPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.attendanceShiftDetails}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                <Navigation.ShiftsAttendanceStack.ShiftDetailsPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.attendanceQR}>
              {params => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <Navigation.ShiftsAttendanceStack.QrAttendancePage spId={Number(params.spId)} key={uuidv4()} />
                </ProtectedRoute>
              )}
            </Route>

            <Route route={routes.users}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                <Navigation.UsersManagmentStack.UsersListPage showOnlyUnconfirmed={false} />
              </ProtectedRoute>
            </Route>
            <Route route={routes.unconfirmedUsers}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                <Navigation.UsersManagmentStack.UsersListPage showOnlyUnconfirmed />
              </ProtectedRoute>
            </Route>
            <Route route={routes.createUser}>
              {(params: { id?: string }) => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                  <Navigation.UsersManagmentStack.AddUser id={params.id} />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.inviteUser}>
              {(params: { email?: string }) => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
                  <Navigation.UsersManagmentStack.InviteUser email={params.email} />
                </ProtectedRoute>
              )}
            </Route>

            <Route route={routes.completeRegistration}>
              <Navigation.CompleteRegistrationStack.CompleteRegistrationPage navigateToScreenName="home" />
            </Route>
            <Route route={routes.signSLA}>
              <>
                <Header title={t('sign_sla.title')} showBack />
                <Navigation.CompleteRegistrationStack.SignSLAPage />
              </>
            </Route>
            <Route route={routes.onboardingFinish}>
              {p => (
                <Navigation.CompleteRegistrationStack.OnboardingFinishedPage
                  navigateToScreenName={p.navigateToScreenName}
                />
              )}
            </Route>

            <Route route={routes.orders}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <DraftJobsStoreProvider value={draftJobsStore}>
                    <Navigation.OrderStack.OrdersListPage ordersState={p.ordersState ?? OrderState.Unconfirmed} />
                  </DraftJobsStoreProvider>
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.orderDraft}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <DraftJobsStoreProvider value={draftJobsStore}>
                    <Navigation.OrderStack.OrderDraftPage orderId={p.orderId} title={p.title} isExtension={false} />
                  </DraftJobsStoreProvider>
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.extendedOrderDraft}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <DraftJobsStoreProvider value={draftJobsStore}>
                    <Navigation.OrderStack.OrderDraftPage orderId={p.orderId} title="Extend Order" isExtension />
                  </DraftJobsStoreProvider>
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.orderConfirmation}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <Navigation.OrderStack.OrderSummaryPage orderId={p.orderId} />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.orderDraftSummary}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <Navigation.OrderStack.OrderDraftSummaryPage orderId={p.orderId} showAmend={p.showAmend === 'true'} />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.orderDuplicate}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <Navigation.OrderStack.OrderDuplicatePage duplicatedOrderId={Number(p.orderId)} />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.orderDuplicateChooseJobs}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <Navigation.OrderStack.OrderDuplicateChooseJobsPage duplicatedOrderId={Number(p.orderId)} />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.completeCompanyDetailsForPaymentTerms}>
              {p => (
                <Navigation.CompleteRegistrationStack.CompleteRegistrationPage
                  navigateToScreenName="orderDraftSummary"
                  orderId={p.orderId}
                  showAmend={p.showAmend}
                />
              )}
            </Route>
            <Route route={routes.orderCreateSuccess}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                <Navigation.OrderStack.OrderConfirmedPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.orderRequestSuccess}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <Navigation.OrderStack.OrderRequestedPage orderId={Number(p.orderId)} />
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.setJobCategory}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <DraftJobsStoreProvider value={draftJobsStore}>
                    <Navigation.OrderStack.JobDraftCategoryPage
                      orderId={p.orderId}
                      draftJobId={p.draftJobId}
                      stepNumber={p.stepNumber ?? String(1)}
                    />
                  </DraftJobsStoreProvider>
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.jobDraftDetails}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <DraftJobsStoreProvider value={draftJobsStore}>
                    <Navigation.OrderStack.JobDraftDetailPage
                      orderId={p.orderId}
                      draftJobId={p.draftJobId}
                      stepNumber={p.stepNumber}
                      isExtension={Boolean(Number(p.isExtension))}
                    />
                  </DraftJobsStoreProvider>
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.jobDraftDetailsPopupField}>
              {p => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}>
                  <DraftJobsStoreProvider value={draftJobsStore}>
                    <Navigation.OrderStack.JobDraftDetailPage
                      orderId={p.orderId}
                      draftJobId={p.draftJobId}
                      activePopupField={p.popupField}
                      stepNumber={p.stepNumber}
                      isExtension={Boolean(Number(p.isExtension))}
                    />
                  </DraftJobsStoreProvider>
                </ProtectedRoute>
              )}
            </Route>
            <Route route={routes.signUp}>{p => <Navigation.AuthStack.SignUpPage redirectTo={p.redirect} />}</Route>
            <Route route={routes.login}>
              {params => (
                <Navigation.AuthStack.LoginPage
                  onLogin={() => {
                    if (params.url) {
                      window.location.href = params.url
                      return
                    }
                    redirect(routes.index, {})
                  }}
                />
              )}
            </Route>
            <Route route={routes.forgotPassword}>
              <Navigation.AuthStack.ForgotPasswordPage />
            </Route>
            <Route route={routes.verifyEmail}>
              {({ email }: { email: string }) => <Navigation.AuthStack.VerifyEmailPage email={email} />}
            </Route>
            <Route route={routes.resetPassword}>
              <Navigation.AuthStack.ResetPasswordPage />
            </Route>
            <Route route={routes.changePassword}>
              <Navigation.AuthStack.ChangePasswordPage />
            </Route>

            <Route route={routes.jobRatingRequests}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                <Navigation.RatingRequestsStack.RatingRequestsListPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.jobRatingDetails}>
              {({ id }: { id: number }) => (
                <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager]}>
                  <Header title={t('job_rating.title')} showBack />
                  <Navigation.RatingRequestsStack.JobRatingPage id={id} />
                </ProtectedRoute>
              )}
            </Route>

            <Route route={routes.setPasswordToHire}>
              <Header title={t('onboarding.create_password')} />
              <Navigation.AuthStack.CreatePasswordPage navigateToScreenName="home" />
            </Route>

            <Route route={routes.setCompanyDetailsToHire}>
              <Navigation.CompleteRegistrationStack.CompleteRegistrationPage navigateToScreenName="home" />
            </Route>

            <Route route={routes.setPasswordToManageSchedule}>
              <Header title={t('onboarding.create_password')} />
              <Navigation.AuthStack.CreatePasswordPage navigateToScreenName="all-jobs" />
            </Route>

            <Route route={routes.setCompanyDetailsToManageSchedule}>
              <Navigation.CompleteRegistrationStack.CompleteRegistrationPage navigateToScreenName="all-jobs" />
            </Route>

            <Route route={routes.knowledgeCenter}>
              <Navigation.KnowledgeCenter />
            </Route>
            <Route route={routes.signUpWithOrder}>
              <Navigation.SignUpWithOrderStack.SelectCategoryPage />
            </Route>
            <Route route={routes.signUpWithOrderForm}>
              <DraftJobsStoreProvider value={draftJobsStore}>
                <Navigation.SignUpWithOrderStack.RegisterClientPage />
              </DraftJobsStoreProvider>
            </Route>
            <Route route={routes.confirmClientInvitation}>
              {params => {
                return (
                  <Navigation.ConfirmClientInvitationStack.ConfirmClientInvitationPage clientId={params.clientId} />
                )
              }}
            </Route>
            <Route route={routes.paymentList}>
              {params => {
                return (
                  <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                    <Navigation.PaymentStack.PaymentListPage
                      paymentStatus={(params.paymentStatus ?? PaymentStatus.Pending) as PaymentStatus}
                    />
                  </ProtectedRoute>
                )
              }}
            </Route>
            <Route route={routes.paymentDetails}>
              {params => {
                return (
                  <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                    <Navigation.PaymentStack.PaymentDetailsPage
                      paymentId={Number(params.paymentId)}
                      hideBack={params.hideBack}
                    />
                  </ProtectedRoute>
                )
              }}
            </Route>
            <Route route={routes.payWithCard}>
              {params => {
                return (
                  <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                    <Navigation.PaymentStack.StripePaymentStack.PayWithCardPage
                      paymentId={Number(params.paymentId)}
                      paymentOptions={params.paymentOptions}
                    />
                  </ProtectedRoute>
                )
              }}
            </Route>
            <Route route={routes.cardPaymentStatus}>
              {params => {
                return (
                  <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                    <Navigation.PaymentStack.StripePaymentStack.CardPaymentStatusPage
                      paymentId={Number(params.paymentId)}
                      paymentOptions={params.paymentOptions}
                    />
                  </ProtectedRoute>
                )
              }}
            </Route>
            <Route route={routes.paymentMethodsList}>
              {params => {
                return (
                  <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                    <Navigation.PaymentStack.PaymentMethodsPage
                      paymentId={Number(params.paymentId)}
                      paymentOptions={params.paymentOptions}
                    />
                  </ProtectedRoute>
                )
              }}
            </Route>
            <Route route={routes.payWithSavedCard}>
              {params => {
                return (
                  <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                    <Navigation.PaymentStack.StripePaymentStack.PayWithSavedCardPage
                      paymentId={Number(params.paymentId)}
                      cardId={params.cardId}
                      paymentOptions={params.paymentOptions}
                    />
                  </ProtectedRoute>
                )
              }}
            </Route>
            <Route route={routes.accountStatement}>
              <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                <Navigation.PaymentStack.AccountStatementPage />
              </ProtectedRoute>
            </Route>
            <Route route={routes.wireTransferDetails}>
              {params => {
                return (
                  <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                    <Navigation.PaymentStack.WireTransferStack.WireTransferDetailsPage
                      paymentId={Number(params.paymentId)}
                    />
                  </ProtectedRoute>
                )
              }}
            </Route>
            <Route route={routes.wireTransferUploadSlip}>
              {params => {
                return (
                  <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}>
                    <Navigation.PaymentStack.WireTransferStack.WireTransferUploadSlipPage
                      paymentId={Number(params.paymentId)}
                    />
                  </ProtectedRoute>
                )
              }}
            </Route>
            <Route route={routes.savedPaymentMethods}>
              <Navigation.PaymentStack.SavedPaymentMethodsPage />
            </Route>
            <Route route={routes.saveCard}>
              <Navigation.PaymentStack.SaveCardPage />
            </Route>

            <Route route={routes.affiliateDashboard}>
              <Navigation.AffiliateStack.DashboardPage />
            </Route>
            <Route route={routes.affiliateReferralsList}>
              <Navigation.AffiliateStack.ReferralsListPage />
            </Route>
            <Route route={routes.affiliateEarnings}>
              <Navigation.AffiliateStack.EarningsPage />
            </Route>
          </main>
        </>
      </div>
    )
  }),
)
