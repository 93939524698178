import { useState, useMemo } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'

import {
  Schedule,
  ScheduleActiveShift,
  ScheduleSp,
  SpShiftStatus,
} from '../../../../../../../../../../models/schedule/types'
import { useMutation, useStore } from '../../../../../../../../../../utils/mst-hooks'
import { DropdownMenu } from '../../../../../../../../../../components/dropdown-menu/dropdown-menu'
import { MenuItem } from '../menu-item/menu-item'
import { CheckInOutModal } from './components/check-in-out-modal/check-in-out-modal'
import { CancelShiftModal } from './components/cancel-shift-modal/cancel-shift-modal'
import { ExtendShiftModal } from './components/extend-shift-modal/extend-shift-modal'
import { images } from './assets'
import { images as pageImages } from '../../assets'
import dropdownHorizontalImg from '../../../../../../../../../../components/assets/context.svg'
import './shift-tools.scss'

type Props = {
  jobSchedule: Schedule
  shift: ScheduleActiveShift
  spShift: ScheduleActiveShift
  spId: number
}

export const ShiftTools = observer(({ jobSchedule, shift, spShift, spId }: Props) => {
  const {
    shiftExtensionDefaultTime,
    checkInOgrammer,
    checkOutOgrammer,
    makeOgrammerFavourite,
    removeOgrammerFromFavourites,
    cancelReasons,
    cancelShift,
  } = useStore().data

  const [, requestCancelShift] = useMutation(cancelShift, {
    onSuccess: () => {
      alert(t('all_jobs.shift_canceled'))
      setCancelModalVisible(false)
      jobSchedule.setOgrammerStatus({ spId, shiftId: shift.shift_id, status: SpShiftStatus.canceled })
    },
    onError: err => {
      alert(err.data.message)
    },
    filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
  })

  const [checkInResult, checkIn] = useMutation(
    (params: { id: number; time: string }) => {
      return checkInOgrammer(params).then(() => {
        jobSchedule.setOgrammerCheckInTime({
          spId,
          shiftId: shift.shift_id,
          time: moment(params.time, 'HH:mm').unix(),
        })
      })
    },
    {
      onSuccess: () => {
        alert(t('all_jobs.og_checked_in'))
        setCheckInModalVisible(false)
      },
      onError: err => {
        alert(err.data.message)
      },
      filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
    },
  )

  const [checkOutResult, checkOut] = useMutation(
    (params: { id: number; time: string }) => {
      return checkOutOgrammer(params).then(() => {
        jobSchedule.setOgrammerCheckOutTime({
          spId,
          shiftId: shift.shift_id,
          time: moment(params.time, 'HH:mm').unix(),
        })
      })
    },
    {
      onSuccess: () => {
        alert(t('all_jobs.og_checked_out'))
        setCheckOutModalVisible(false)
      },
      onError: err => {
        alert(err.data.message)
      },
      filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
    },
  )

  const [favourResult, favourOgrammer] = useMutation(makeOgrammerFavourite, {
    onSuccess: () => jobSchedule.toogleOgrammerFavouriteStatus({ spId }),
    onError: err => {
      alert(err.data.message)
    },
    filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
  })

  const [unfavourResult, unfavourOgrammer] = useMutation(removeOgrammerFromFavourites, {
    onSuccess: () => jobSchedule.toogleOgrammerFavouriteStatus({ spId }),
    onError: err => {
      alert(err.data.message)
    },
    filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
  })

  const [checkInModalVisible, setCheckInModalVisible] = useState(false)
  const [checkOutModalVisible, setCheckOutModalVisible] = useState(false)
  const [extendModalVisible, setExtendModalVisible] = useState(false)
  const [cancelModalVisible, setCancelModalVisible] = useState(false)

  const { t } = useTranslation()

  const isLessThan12HoursToShift = useMemo(() => {
    if (!spShift.start_time) return false
    const currentMomentDate = moment()
    const startTimeMomentDate = moment(spShift.start_time * 1000)
    const diff = startTimeMomentDate.diff(currentMomentDate)
    const hoursUntilCheckin = moment.duration(diff).asHours()
    return hoursUntilCheckin < 12
  }, [])

  const { status, client_checkin_time, client_checkout_time } = shift

  const sp = jobSchedule.getSp({ spId }) as ScheduleSp
  const { name } = sp

  const getCheckInDisabledStatus = () => {
    return Boolean(client_checkin_time) || !isLessThan12HoursToShift || checkInResult.isLoading
  }

  const getCheckoutDisabledStatus = () => {
    return (
      !Boolean(client_checkin_time) ||
      Boolean(client_checkout_time) ||
      !isLessThan12HoursToShift ||
      checkOutResult.isLoading
    )
  }

  return (
    <>
      <div className="ShiftTools">
        <DropdownMenu
          outerComponent={
            <div className="ShiftTools__dropdownContainer">
              <img src={dropdownHorizontalImg} alt="ogrammer tools" />
            </div>
          }
          items={[
            <MenuItem
              key="check-in"
              img={pageImages.clockImg}
              title={t('job_shifts.check_in')}
              onClick={() => setCheckInModalVisible(true)}
              disabled={getCheckInDisabledStatus()}
            />,
            <MenuItem
              key="check-out"
              img={images.checkOutImg}
              title={t('job_shifts.check_out')}
              onClick={() => setCheckOutModalVisible(true)}
              disabled={getCheckoutDisabledStatus()}
            />,
            <MenuItem
              key="extend"
              img={images.extendImg}
              title={t('job_shifts.extend_shift')}
              onClick={() => setExtendModalVisible(true)}
              disabled={
                !isLessThan12HoursToShift ||
                Boolean(client_checkout_time) ||
                status.id === SpShiftStatus.canceled ||
                status.id === SpShiftStatus.completed
              }
            />,
            <MenuItem
              key="cancel"
              img={pageImages.closeImg}
              title={t('job_shifts.cancel_shift')}
              onClick={() => setCancelModalVisible(true)}
              disabled={
                Boolean(client_checkin_time) ||
                Boolean(client_checkout_time) ||
                status.id === SpShiftStatus.started ||
                status.id === SpShiftStatus.canceled
              }
            />,
            ...(sp.phone !== null
              ? [
                  <MenuItem
                    key="call"
                    img={images.phoneImg}
                    title={t('job_shifts.call')}
                    onClick={() => {
                      window.open(`tel:+${sp.phone}`)
                    }}
                    disabled={false}
                  />,
                ]
              : []),
            ...(sp.phone !== null
              ? [
                  <MenuItem
                    key="message"
                    img={images.messageImg}
                    title={t('job_shifts.whatsapp')}
                    onClick={() => {
                      window.open(`https://wa.me/${sp.phone}`)
                    }}
                    disabled={false}
                  />,
                ]
              : []),
            <MenuItem
              key="fav"
              img={sp.is_favorite ? images.heartFilledImg : images.heartImg}
              title={sp.is_favorite ? t('job_shifts.remove_from_favourites') : t('job_shifts.add_to_favourites')}
              onClick={() => {
                if (sp.is_favorite) {
                  unfavourOgrammer({ spId })
                } else {
                  favourOgrammer({ spId })
                }
              }}
              disabled={favourResult.isLoading || unfavourResult.isLoading}
            />,
          ]}
        />
      </div>
      <CheckInOutModal
        label={t('job_shifts.check_in')}
        modalVisible={checkInModalVisible}
        onConfirm={time => {
          checkIn({ id: shift.active_shift_id, time })
        }}
        onClose={() => setCheckInModalVisible(false)}
      />
      <CheckInOutModal
        label={t('job_shifts.check_out')}
        modalVisible={checkOutModalVisible}
        onConfirm={time => {
          checkOut({ id: shift.active_shift_id, time })
        }}
        onClose={() => setCheckOutModalVisible(false)}
      />
      <ExtendShiftModal
        shiftId={shift.active_shift_id}
        label={t('job_shifts.extra_time')}
        modalVisible={extendModalVisible}
        shiftExtensionDefaultTime={shiftExtensionDefaultTime}
        onClose={() => setExtendModalVisible(false)}
      />
      <CancelShiftModal
        cancelReasons={cancelReasons}
        shiftId={shift.active_shift_id}
        ogrammerName={name}
        modalVisible={cancelModalVisible}
        onClose={() => setCancelModalVisible(false)}
        onSubmit={requestCancelShift}
      />
    </>
  )
})
